@import "@mojoactive/components/dist/styles.css";
@import "core/styles/core.scss";

@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  @apply bg-gray-100;
}

a {
  @apply text-blue-500;
}

// -------------------------------------------------
// Documentation Styles
// -------------------------------------------------
.prose {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    a {
      position: relative;
    }
    &:hover a[href^="#"] span:before {
      opacity: 1;
    }
    a[href^="#"] span {
      &:hover {
        color: #696c73;
      }
      &:before {
        content: "#";
        position: absolute;
        margin-left: -26px;
        opacity: 0;
        transition: all 0.3s ease-in-out;
      }
    }
  }

  h1 {
    margin-bottom: 0 !important;
  }

  .h1 {
    font-size: 2.6666667em;
    line-height: 1;
    color: #111827;
    font-weight: 800;
  }

  a {
    text-decoration: none;
    color: inherit;
  }

  a:not(.tab-link) {
    text-decoration: none;
    @apply text-orange-500;
    &:hover {
      text-decoration: underline;
    }
  }

  code {
    @apply text-orange-500;
    font-weight: 400;
    font-size: 1rem;
  }
  pre code {
    @apply text-gray-200;
  }

  img {
    cursor: zoom-in;
  }

  ul.contains-task-list {
    list-style: none;
    li.task-list-item {
      padding-left: 0;
      *:first-child {
        margin-top: 0.3rem;
      }
      *:last-child {
        margin-bottom: 0.3rem;
      }
      &:before {
        display: none;
      }
    }
  }

  .token.class-name,
  .token.function,
  .token.selector,
  .token.selector .class,
  .token.tag {
    --tw-text-opacity: 1;
    color: rgba(232, 121, 249, var(--tw-text-opacity));
  }

  .token.attr-name,
  .token.important,
  .token.keyword,
  .token.operator,
  .token.pseudo-class,
  .token.rule {
    --tw-text-opacity: 1;
    color: rgba(34, 211, 238, var(--tw-text-opacity));
  }

  .token.attr-value,
  .token.class,
  .token.color,
  .token.number,
  .token.string,
  .token.unit {
    --tw-text-opacity: 1;
    color: rgba(190, 242, 100, var(--tw-text-opacity));
  }

  .token.module,
  .token.property,
  .token.punctuation {
    --tw-text-opacity: 1;
    color: rgba(186, 230, 253, var(--tw-text-opacity));
  }

  .token.atapply .token:not(.rule):not(.important) {
    color: inherit;
  }

  .language-shell .token:not(.comment) {
    color: inherit;
  }

  .language-css .token.function {
    color: inherit;
  }

  .token.comment {
    --tw-text-opacity: 1;
    color: rgba(156, 163, 175, var(--tw-text-opacity));
  }

  .token.deleted:not(.prefix) {
    display: block;
    margin-left: -1rem;
    margin-right: -1rem;
    padding-left: 1rem;
    padding-right: 1rem;
    position: relative;
  }

  .token.deleted:not(.prefix):after {
    content: "";
    --tw-bg-opacity: 1;
    background-color: rgba(251, 113, 133, var(--tw-bg-opacity));
    --tw-bg-opacity: 0.25;
    display: block;
    pointer-events: none;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  .token.deleted.prefix {
    --tw-text-opacity: 1;
    color: rgba(156, 163, 175, var(--tw-text-opacity));
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  .token.inserted:not(.prefix) {
    --tw-bg-opacity: 1;
    background-color: rgba(4, 120, 87, var(--tw-bg-opacity));
    --tw-bg-opacity: 0.5;
    display: block;
    margin-left: -1rem;
    margin-right: -1rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .token.inserted.prefix {
    --tw-text-opacity: 1;
    color: rgba(167, 243, 208, var(--tw-text-opacity));
    --tw-text-opacity: 0.75;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  .mce-content-body [contentEditable="false"]:not(pre) > code[class*="language-"],
  pre[class*="language-"] {
    font-size: 0.8888889em;
    line-height: 1.75;
    margin-top: 2em;
    margin-bottom: 2em;
    border-radius: 0.375rem;
    padding-top: 1em;
    padding-right: 1.5em;
    padding-bottom: 1em;
    padding-left: 1.5em;
    color: #e5e7eb;
    background-color: #1f2937;
    text-shadow: none !important;
  }
  .language-css .token.string,
  .style .token.string,
  .token.entity,
  .token.operator,
  .token.url {
    background: none;
    color: rgb(34, 211, 238);
  }

  .token.boolean,
  .token.constant,
  .token.deleted,
  .token.number,
  .token.property,
  .token.symbol,
  .token.tag {
    color: rgb(229, 231, 235);
  }

  .mce-content-body:focus-visible {
    outline: none;
  }
}

.JSXElement.JSXIdentifier {
  color: #4ec9b0 !important;
}
.JSXElement.JSXBracket {
  color: #808080 !important;
}
.JSXElement.JSXText {
  color: #d4d4d4 !important;
}
.JSXElement.JSXGlyph {
  background: #9cdcfe !important;
  opacity: 0.25;
}
.JSXOpeningFragment.JSXBracket {
  color: #9cdcfe !important;
  font-weight: bold;
}
.JSXClosingFragment.JSXBracket {
  color: #9cdcfe !important;
  font-weight: bold;
}
.JSXOpeningElement.JSXBracket {
  color: #9cdcfe !important;
  font-weight: bold;
}
.JSXOpeningElement.JSXIdentifier {
  color: #9cdcfe;
}
.JSXClosingElement.JSXBracket {
  color: #9cdcfe !important;
  font-weight: lighter;
}
.JSXClosingElement.JSXIdentifier {
  color: #9cdcfe;
  font-weight: lighter;
}
.JSXAttribute.JSXIdentifier {
  color: #9cdcfe !important;
}
.JSXExpressionContainer.JSXBracket {
  color: #9cdcfe !important;
}
.JSXSpreadChild.JSXBracket {
  color: #9cdcfe !important;
}
.JSXSpreadAttribute.JSXBracket {
  color: #9cdcfe !important;
}

.hint {
  @apply flex items-center rounded-md p-4 text-base font-semibold;
  margin: 15px 0;

  svg {
    margin-right: 0.6rem;
  }

  &.tip {
    @apply bg-blue-200 text-blue-700;
    svg {
      @apply text-blue-600;
    }
  }
  &.warning {
    @apply bg-orange-200 text-orange-700;
    svg {
      @apply text-orange-600;
    }
  }
  &.danger {
    @apply bg-red-200 text-red-700;
    svg {
      @apply text-red-600;
    }
  }
}

:root {
  --balloon-border-radius: 5px;
  --balloon-color: rgba(75, 75, 75, 0.95);
  --balloon-text-color: #fff;
  --balloon-font-size: 12px;
  --balloon-move: 4px;
}

button[aria-label][data-balloon-pos] {
  overflow: visible;
}

[aria-label][data-balloon-pos] {
  position: relative;
  cursor: pointer;
}
[aria-label][data-balloon-pos]:after {
  opacity: 0;
  pointer-events: none;
  transition: all 0.18s ease-out 0.75s;
  text-indent: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-weight: normal;
  font-style: normal;
  text-shadow: none;
  font-size: var(--balloon-font-size);
  font-weight: 500;
  background: var(--balloon-color);
  border-radius: 2px;
  color: var(--balloon-text-color);
  border-radius: var(--balloon-border-radius);
  content: attr(aria-label);
  padding: 0.5em 1em;
  position: absolute;
  white-space: nowrap;
  z-index: 10;
}
[aria-label][data-balloon-pos]:before {
  width: 0;
  height: 0;
  border: 5px solid transparent;
  border-top-color: var(--balloon-color);
  opacity: 0;
  pointer-events: none;
  transition: all 0.18s ease-out 0.75s;
  content: "";
  position: absolute;
  z-index: 10;
}
[aria-label][data-balloon-pos]:hover:before,
[aria-label][data-balloon-pos]:hover:after,
[aria-label][data-balloon-pos][data-balloon-visible]:before,
[aria-label][data-balloon-pos][data-balloon-visible]:after {
  opacity: 1;
  pointer-events: none;
}
[aria-label][data-balloon-pos].font-awesome:after {
  font-family: FontAwesome, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
    sans-serif;
}
[aria-label][data-balloon-pos][data-balloon-break]:after {
  white-space: pre;
}
[aria-label][data-balloon-pos][data-balloon-break][data-balloon-length]:after {
  white-space: pre-line;
  word-break: break-word;
}
[aria-label][data-balloon-pos][data-balloon-blunt]:before,
[aria-label][data-balloon-pos][data-balloon-blunt]:after {
  transition: none;
}
[aria-label][data-balloon-pos][data-balloon-pos="up"]:hover:after,
[aria-label][data-balloon-pos][data-balloon-pos="up"][data-balloon-visible]:after,
[aria-label][data-balloon-pos][data-balloon-pos="down"]:hover:after,
[aria-label][data-balloon-pos][data-balloon-pos="down"][data-balloon-visible]:after {
  transform: translate(-50%, 0);
}
[aria-label][data-balloon-pos][data-balloon-pos="up"]:hover:before,
[aria-label][data-balloon-pos][data-balloon-pos="up"][data-balloon-visible]:before,
[aria-label][data-balloon-pos][data-balloon-pos="down"]:hover:before,
[aria-label][data-balloon-pos][data-balloon-pos="down"][data-balloon-visible]:before {
  transform: translate(-50%, 0);
}
[aria-label][data-balloon-pos][data-balloon-pos*="-left"]:after {
  left: 0;
}
[aria-label][data-balloon-pos][data-balloon-pos*="-left"]:before {
  left: 5px;
}
[aria-label][data-balloon-pos][data-balloon-pos*="-right"]:after {
  right: 0;
}
[aria-label][data-balloon-pos][data-balloon-pos*="-right"]:before {
  right: 5px;
}
[aria-label][data-balloon-pos][data-balloon-pos*="-left"]:hover:after,
[aria-label][data-balloon-pos][data-balloon-pos*="-left"][data-balloon-visible]:after,
[aria-label][data-balloon-pos][data-balloon-pos*="-right"]:hover:after,
[aria-label][data-balloon-pos][data-balloon-pos*="-right"][data-balloon-visible]:after {
  transform: translate(0, 0);
}
[aria-label][data-balloon-pos][data-balloon-pos*="-left"]:hover:before,
[aria-label][data-balloon-pos][data-balloon-pos*="-left"][data-balloon-visible]:before,
[aria-label][data-balloon-pos][data-balloon-pos*="-right"]:hover:before,
[aria-label][data-balloon-pos][data-balloon-pos*="-right"][data-balloon-visible]:before {
  transform: translate(0, 0);
}
[aria-label][data-balloon-pos][data-balloon-pos^="up"]:before,
[aria-label][data-balloon-pos][data-balloon-pos^="up"]:after {
  bottom: 100%;
  transform-origin: top;
  transform: translate(0, var(--balloon-move));
}
[aria-label][data-balloon-pos][data-balloon-pos^="up"]:after {
  margin-bottom: 10px;
}
[aria-label][data-balloon-pos][data-balloon-pos="up"]:before,
[aria-label][data-balloon-pos][data-balloon-pos="up"]:after {
  left: 50%;
  transform: translate(-50%, var(--balloon-move));
}
[aria-label][data-balloon-pos][data-balloon-pos^="down"]:before,
[aria-label][data-balloon-pos][data-balloon-pos^="down"]:after {
  top: 100%;
  transform: translate(0, calc(var(--balloon-move) * -1));
}
[aria-label][data-balloon-pos][data-balloon-pos^="down"]:after {
  margin-top: 10px;
}
[aria-label][data-balloon-pos][data-balloon-pos^="down"]:before {
  width: 0;
  height: 0;
  border: 5px solid transparent;
  border-bottom-color: var(--balloon-color);
}
[aria-label][data-balloon-pos][data-balloon-pos="down"]:after,
[aria-label][data-balloon-pos][data-balloon-pos="down"]:before {
  left: 50%;
  transform: translate(-50%, calc(var(--balloon-move) * -1));
}
[aria-label][data-balloon-pos][data-balloon-pos="left"]:hover:after,
[aria-label][data-balloon-pos][data-balloon-pos="left"][data-balloon-visible]:after,
[aria-label][data-balloon-pos][data-balloon-pos="right"]:hover:after,
[aria-label][data-balloon-pos][data-balloon-pos="right"][data-balloon-visible]:after {
  transform: translate(0, -50%);
}
[aria-label][data-balloon-pos][data-balloon-pos="left"]:hover:before,
[aria-label][data-balloon-pos][data-balloon-pos="left"][data-balloon-visible]:before,
[aria-label][data-balloon-pos][data-balloon-pos="right"]:hover:before,
[aria-label][data-balloon-pos][data-balloon-pos="right"][data-balloon-visible]:before {
  transform: translate(0, -50%);
}
[aria-label][data-balloon-pos][data-balloon-pos="left"]:after,
[aria-label][data-balloon-pos][data-balloon-pos="left"]:before {
  right: 100%;
  top: 50%;
  transform: translate(var(--balloon-move), -50%);
}
[aria-label][data-balloon-pos][data-balloon-pos="left"]:after {
  margin-right: 10px;
}
[aria-label][data-balloon-pos][data-balloon-pos="left"]:before {
  width: 0;
  height: 0;
  border: 5px solid transparent;
  border-left-color: var(--balloon-color);
}
[aria-label][data-balloon-pos][data-balloon-pos="right"]:after,
[aria-label][data-balloon-pos][data-balloon-pos="right"]:before {
  left: 100%;
  top: 50%;
  transform: translate(calc(var(--balloon-move) * -1), -50%);
}
[aria-label][data-balloon-pos][data-balloon-pos="right"]:after {
  margin-left: 10px;
}
[aria-label][data-balloon-pos][data-balloon-pos="right"]:before {
  width: 0;
  height: 0;
  border: 5px solid transparent;
  border-right-color: var(--balloon-color);
}
[aria-label][data-balloon-pos][data-balloon-length]:after {
  white-space: normal;
}
[aria-label][data-balloon-pos][data-balloon-length="small"]:after {
  width: 80px;
}
[aria-label][data-balloon-pos][data-balloon-length="medium"]:after {
  width: 150px;
}
[aria-label][data-balloon-pos][data-balloon-length="large"]:after {
  width: 260px;
}
[aria-label][data-balloon-pos][data-balloon-length="xlarge"]:after {
  width: 380px;
}
@media screen and (max-width: 768px) {
  [aria-label][data-balloon-pos][data-balloon-length="xlarge"]:after {
    width: 90vw;
  }
}
[aria-label][data-balloon-pos][data-balloon-length="fit"]:after {
  width: 100%;
}

.spin {
  -webkit-animation-name: spin;
  -webkit-animation-duration: 1500ms;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  -moz-animation-name: spin;
  -moz-animation-duration: 1500ms;
  -moz-animation-iteration-count: infinite;
  -moz-animation-timing-function: linear;
  -ms-animation-name: spin;
  -ms-animation-duration: 1500ms;
  -ms-animation-iteration-count: infinite;
  -ms-animation-timing-function: linear;
  animation-name: spin;
  animation-duration: 1500ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@-ms-keyframes spin {
  from {
    -ms-transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(360deg);
  }
}

@-moz-keyframes spin {
  from {
    -moz-transform: rotate(0deg);
  }
  to {
    -moz-transform: rotate(360deg);
  }
}

@-webkit-keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.fancy-scroll::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}
.fancy-scroll::-webkit-scrollbar-track {
  border-radius: 0;
  box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.2);
}
.fancy-scroll::-webkit-scrollbar-corner {
  background: rgba(0, 0, 0, 0.2);
}
.fancy-scroll::-webkit-scrollbar-thumb {
  border-radius: 30px;
  @apply bg-gray-400;
}

@media print {
  .print-hide {
    display: none;
  }
  .print-container {
    padding: 20px;
  }
}

// Prevent the css purge from messing with default dynamic buttons
.btn-blue {
  @apply bg-blue-600 hover:bg-blue-700;
  &:focus {
    @apply ring-blue-500;
  }
}
.btn-red {
  @apply bg-red-600 hover:bg-red-700;
  &:focus {
    @apply ring-red-500;
  }
}
.btn-green {
  @apply bg-green-600 hover:bg-green-700;
  &:focus {
    @apply ring-green-500;
  }
}
.btn-gray {
  @apply bg-gray-600 hover:bg-gray-700;
  &:focus {
    @apply ring-gray-500;
  }
}

.btn-blue-outline {
  @apply border-blue-200 bg-blue-100 text-blue-700;
  &:hover {
    @apply bg-blue-200;
  }
  &:focus {
    @apply ring-blue-500;
  }
}
.btn-red-outline {
  @apply border-red-200 bg-red-100 text-red-700;
  &:hover {
    @apply bg-red-200;
  }
  &:focus {
    @apply ring-red-500;
  }
}
.btn-green-outline {
  @apply border-green-200 bg-green-100 text-green-700;
  &:hover {
    @apply bg-green-200;
  }
  &:focus {
    @apply ring-green-500;
  }
}
.btn-gray-outline {
  @apply border-gray-200 bg-gray-100 text-gray-700;
  &:hover {
    @apply bg-gray-200;
  }
  &:focus {
    @apply ring-gray-500;
  }
}

.audit-log-data {
  a {
    @apply text-white underline hover:text-orange-300;
  }
}

.LinkListCon {
  margin-top: 0.75rem;
  border: 1px solid #696c73;
  border-radius: 5px;
}

.LinkList {
  padding: 0.5rem;
}

.LinkListItem {
  display: block;
  color: #696c73;
  text-decoration: underline;
  &:hover {
    text-decoration: none;
  }
}

.ZoneMapLink {
  color: #000000;
}

.react-autosuggest__input {
  @apply block w-full rounded-md border-gray-300 py-2.5 shadow-sm focus:border-blue-300 focus:ring-blue-300 sm:text-sm;
}

.react-autosuggest__suggestions-container {
  @apply absolute mt-1 overflow-y-auto rounded-md border bg-white shadow-lg;
  display: none;
  min-width: 250px;
  max-height: 250px;

  &.react-autosuggest__suggestions-container--open {
    display: block;
  }

  .react-autosuggest__suggestions-list {
    @apply divide-y;
    .react-autosuggest__suggestion {
      @apply cursor-pointer px-3 py-2 transition duration-150 ease-in-out;
      &:hover {
        @apply bg-gray-100;
      }
      &[aria-selected="true"] {
        @apply bg-gray-100;
      }
    }
  }
}

#tooltip {
  background: #333;
  color: white;
  font-weight: bold;
  padding: 4px 8px;
  font-size: 13px;
  border-radius: 4px;
  display: none;
  z-index: 999;
}

#tooltip[data-show] {
  display: block;
}

#arrow,
#arrow::before {
  position: absolute;
  width: 8px;
  height: 8px;
  background: inherit;
}

#arrow {
  visibility: hidden;
}

#arrow::before {
  visibility: visible;
  content: "";
  transform: rotate(45deg);
}

#tooltip[data-popper-placement^="top"] > #arrow {
  bottom: -4px;
}

#tooltip[data-popper-placement^="bottom"] > #arrow {
  top: -4px;
}

#tooltip[data-popper-placement^="left"] > #arrow {
  right: -4px;
}

#tooltip[data-popper-placement^="right"] > #arrow {
  left: -4px;
}

.uppy-Dashboard-inner {
  top: 20px !important;
  left: 0 !important;
  right: 0 !important;
  transform: none !important;
  margin: 0 auto !important;
  animation: none !important;
}

[aria-label="Tabs"] button:focus {
  outline: none;
}

.tox.tox-tinymce {
  border: none;
  .tox-editor-header {
    border-top: 1px solid #ccc;
    border-left: 1px solid #ccc;
    border-right: 1px solid #ccc;
  }
}

.tox-collection__item-label * {
  font-size: 16px !important;
}

.mce-content-body {
  outline: none;
}

#toolbar-container {
  .tox.tox-tinymce {
    display: block !important;
    visibility: visible;
  }
}

// .tox-editor-header {
//   max-width: 871px !important;
//   position: fixed !important;
//   left: 0 !important;
//   top: 0 !important;
//   right: 0 !important;
//   margin: 0 auto !important;
// }
